import { useMemo } from "react";

import { readState } from "@/__main__/app-state.mjs";
import { MIN_STRING_DISTANCE } from "@/app/constants.mjs";
import {
  ROLE_SYMBOL_TO_STR,
  ROLE_SYMBOLS,
} from "@/game-lol/constants/constants.mjs";
import {
  dataDuosList,
  dataSinglesList,
  getKeyForArenaChampionsData,
  getPatchForArenaData,
} from "@/game-lol/utils/arena-queue-utils.mjs";
import {
  getCurrentPatchForStaticData,
  getStaticData,
  isARAMQueue,
  isURFQueue,
} from "@/game-lol/utils/util.mjs";
import { calcRate } from "@/util/helpers.mjs";
import stringCompare from "@/util/string-compare.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const useChampionsTierlist = ({
  filterParams,
  searchParams,
  isArenaSingles,
  isArenaDuos,
}) => {
  const { searchText, role, queue } = filterParams;
  const patch = getCurrentPatchForStaticData();
  const champions = getStaticData("champions", patch);
  const isArena = isArenaSingles || isArenaDuos;

  const {
    lol: { championStats = {} },
  } = useSnapshot(readState);

  const stats = isArena
    ? championStats[
        getKeyForArenaChampionsData(getPatchForArenaData(), isArenaDuos)
      ]
    : championStats?.[searchParams.toString()];

  const tableData = useMemo(() => {
    if (!champions || !stats || stats instanceof Error) {
      return null;
    }

    if (isArenaSingles) {
      return dataSinglesList(stats, champions);
    } else if (isArenaDuos) {
      return dataDuosList(stats, champions);
    }

    return stats
      .map((champStats) => {
        const championKey = champions?.keys?.[champStats.championId];
        const champion = champions[championKey];
        if (!champion) return null;
        const championName = champion.name;
        return {
          id: champStats.championId,
          patch: champStats.patch,
          role: champStats.role,
          championKey,
          championName,
          tier: champStats.tierListTier?.tierRank || 5,
          winRate: calcRate(champStats.wins, champStats.games),
        };
      })
      .filter(Boolean);
  }, [champions, stats, isArenaSingles]);

  const filteredData = useMemo(() => {
    const aramUrfFilter = isARAMQueue(queue) || isURFQueue(queue);

    const data =
      !role || aramUrfFilter
        ? tableData
        : tableData?.filter(
            (c) =>
              role === ROLE_SYMBOL_TO_STR[c.role]?.gql ||
              role === ROLE_SYMBOL_TO_STR[ROLE_SYMBOLS.all].gql,
          );

    return data
      ?.filter((c) => {
        const name = !isArenaDuos
          ? c.championName
          : `${c.champion1Name}${c.champion2Name}`;
        return (
          !searchText || stringCompare(searchText, name) > MIN_STRING_DISTANCE
        );
      })
      ?.sort((a, b) => {
        return (
          a.tier - b.tier ||
          b.top4Rate - a.top4Rate ||
          b.winRate - a.winRate ||
          b.games - a.games
        );
      })
      ?.reduce((acc, curr) => {
        const grp = curr.tier - 1;

        if (!acc[grp]) acc[grp] = [];
        acc[grp] = [...acc[grp], curr];

        return acc;
      }, []);
  }, [tableData, searchText, role, queue]);

  return filteredData;
};

export default useChampionsTierlist;
